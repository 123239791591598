import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import UserContext from '../UserContext';
import Schedule from './Schedule';
import Gallery from './Gallery';
import Snippet from './Snippet';

const Dashboard = () => {
  const { user, setUser } = useContext(UserContext);

  return (
    <div>
      <Snippet />
      <h2>Schedule</h2>
      <Schedule schedule={user.schedule} files={user.files} />
      <h2>Gallery</h2>
      <Gallery files={user.files} />
    </div>
  );
}

export default Dashboard;
