import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import UserContext from '../UserContext';
const baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:9090/dev';

const UploadCustom = () => {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);

  const handleChange = async event => {
    event.persist();

    const image = event.target.files[0];

    const res = await axios.get(
      `${baseURL}/upload/custom?fileName=${image.name}`, {
        headers: {
          Authorization: user.email,
        },
      },
    );

    const { url } = res.data;

    await axios.request({
      url,
      method: 'put',
      data: image,
    });

    setUser(res.data.user);

    history.push('/dashboard');
  };

  return (
    <>
      <input
        type="file"
        name="uploadFile"
        onChange={handleChange}
      />
    </>
  )
};

export default UploadCustom;
