import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../UserContext';

import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:9090/dev';

const Login = () => {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);

  const onSignIn = googleUser => {
    const gUser = googleUser.getBasicProfile();

    axios.post(`${baseURL}/google/auth`, {
      id: gUser.getId(),
      email: gUser.getEmail(),
      name: gUser.getName()
    }).then(res => {
      setUser(res.data.user);

      if (res.data.user.files.length === 0) {
        history.push('/upload/custom');
      } else {
        history.push('/dashboard');
      }
    }).catch(error => {
      throw error;
    });
  }

  useEffect(() => {
    window.gapi.signin2.render('signInButton', {
      onsuccess: onSignIn,
    });
  }, []);


  return (
    <>
      <div id="signInButton" />
    </>
  );
}

export default Login;
