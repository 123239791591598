import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../UserContext';
import './Snippet.css';

const Snippet = () => {
  const { user } = useContext(UserContext);
  const [i, setI] = useState(0);

  useEffect(() => {
    setI(i + 1);
  }, [user.active]);

  const previewUrl = `https://logogogo.s3.amazonaws.com/active/${user.id}.png`;

  return (
    <div>
      <div>
        <img className="preview-logo" src={`https://logogogo.s3.amazonaws.com/active/${user.id}.png?v=${i}`} />
      </div>
      Preview
      <br />
      Use this as the <code>src</code> for your <code>img</code> tag
      <pre className="snippet">
        {previewUrl}
      </pre>
    </div>
  );
};

export default Snippet;
