import React, { useMemo, useState, useEffect }  from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useHistory,
} from 'react-router-dom';

import logo from './logo.svg';
import './App.css';

import Login from './onboard/Login';
import UploadCustom from './upload/UploadCustom';
import Dashboard from './dashboard/Dashboard';

import UserContext from './UserContext';

function App() {
  const history = useHistory();

  const originalUser = useMemo(() => {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }, []);

  const [user, setUser] = useState(originalUser);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  const value = {
    user,
    setUser
  };

  return (
    <UserContext.Provider value={value}>
      <div className="nav-bar">
        <div className="logo">
          LOGOGOGO
        </div>
      </div>
      <div className="Page">
        <BrowserRouter>
          <Switch>
            <Route path="/upload/custom">
              <UploadCustom />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </UserContext.Provider>
  );
}

export default App;
