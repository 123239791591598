import { useContext } from 'react';
import axios from 'axios';
import UserContext from './UserContext';

const baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:9090/dev';

export const useUserService = () => {
  const { user, setUser } = useContext(UserContext);

  const contextualAxios = axios.create({
    baseURL,
    headers: {
      Authorization: user.email,
    },
  });

  return {
    createScheduled: body => contextualAxios.post('/schedule', body).then(res => setUser(res.data.user)),
    updateScheduled: (id, body) => contextualAxios.put(`/schedule/${id}`, body).then(res => setUser(res.data.user)),
    deleteScheduled: id => contextualAxios.delete(`/schedule/${id}`).then(res => setUser(res.data.user)),
  }
};
