import React from 'react';
import { useHistory } from 'react-router-dom';
import './Grid.css';

const Gallery = ({ files }) => {
  const history = useHistory();

  const uploadCustom = () => {
    history.push('/upload/custom');
  };

  return (
    <div className="gallery-grid">
      {files.map(file => (
        <div className="preview">
          <img src={`https://logogogo.s3.amazonaws.com/${file.s3Key}`} />
        </div>
      ))}
      <div className="preview preview-add" onClick={uploadCustom}>
        Add
      </div>
    </div>

  );
};

export default Gallery;
