import React, { useEffect, useState, useMemo } from 'react';
import { useTable } from 'react-table';
import { Formik } from 'formik';
import './Schedule.css';
import { useUserService } from '../hooks';

const Row = ({ id, date, name, previewUrl, files, s3Key }) => {
  const { deleteScheduled } = useUserService();
  const [showEdit, setShowEdit] = useState(false);
  const selectedFile = files.find(file => file.s3Key === s3Key);
  const dateString = new Date(date);

  const value = {
    date,
    name,
    file: selectedFile,
  };

  return (
    <div key={id} className={`row ${showEdit && 'row-edit'}`}>
      {showEdit && (
        <ScheduleForm isNew={false} id={id} setOpen={setShowEdit} files={files} value={value} />
      )}
      {!showEdit && (
        <>
          <div className="date">
            {dateString.getMonth()+1}/{dateString.getDate()}/{dateString.getUTCFullYear()}
          </div>
          <div className="name">
            {name}
          </div>
          <div className="preview-url">
            <img alt={name} src={previewUrl} />
          </div>
          <div className="actions">
            <div role="img" aria-label="Skip" className="action action-delete" onClick={() => deleteScheduled(id) }>
              Delete
            </div>
            <div role="img" aria-label="Edit" className="action" onClick={() => setShowEdit(true) }>
              Edit
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SelectImage = ({ files, onChange, handleBlur, value, name, setFieldValue }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    if (selected) {
      setFieldValue(name, selected);
    }
  }, [selected, name, setFieldValue]);

  return (
    <div className="select-image">
      <div className="select-image-trigger" onClick={() => setOpen(!open)}>
        {selected ?
          <img alt={selected.s3Key} src={`https://logogogo.s3.amazonaws.com/${selected.s3Key}`} />
          : <div className="action">Select image</div>}
      </div>
      {open && (
        <div className="select-image-popup">
          {files.map(file => {
            const previewUrl = `https://logogogo.s3.amazonaws.com/${file.s3Key}`;

            return (
              <img alt={file.s3Key} key={file.s3Key} src={previewUrl} onClick={() => { setSelected(file); setOpen(false); }} />
            );
          })}
        </div>
      )}
    </div>
  );
};

const ScheduleForm = ({ id, isNew, files, setOpen, value }) => {
  const { createScheduled, updateScheduled } = useUserService();

  const initialValues = isNew ? { date: '', name: '', file: undefined } : value;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ( values, { setSubmitting }) => {
        setSubmitting(true);

        if (isNew) {
          await createScheduled(values);
        } else {
          await updateScheduled(id, values);
        }

        setSubmitting(false);
        setOpen(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="date">
            <input type="date" name="date" onChange={handleChange} onBlur={handleBlur} value={values.date} />
          </div>
          <div className="name">
            <input type="text" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name} />
          </div>
          <div className="preview-url">
            <SelectImage files={files} onChange={handleChange} setFieldValue={setFieldValue} onBlur={handleBlur} value={values.file} name="file" />
          </div>
          <div className="actions" onClick={handleSubmit}>
            <div role="img" aria-label="save" className="action">
              Save
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

const Schedule = ({ schedule, files }) => {
  const [newOpen, setNewOpen] = useState(false);

  const scheduleNew = () => {
    if (!newOpen) {
      setNewOpen(true);
    }
  };

  return (
    <div className="schedule">
      <div className="row header">
        <div className="date">Start Date</div>
        <div className="name">Name</div>
        <div className="preview-url">Preview</div>
      </div>
      {schedule.map(({ id, fileHash, startDate, name, s3Key }) => {
        const previewUrl = `https://logogogo.s3.amazonaws.com/${s3Key}`;

        return (
          <Row files={files} id={id} date={startDate} name={name} s3Key={s3Key} previewUrl={previewUrl} />
        );
      })}
          <div className={`row row-click schedule-new ${newOpen ? 'schedule-new-open' : 'row-click-hover'}`} onClick={scheduleNew}>
        { newOpen ? <ScheduleForm isNew={true} setOpen={setNewOpen} files={files} /> : '✚' }
      </div>
    </div>
  );
}

export default Schedule;
